








import { MetaInfo } from 'vue-meta'
import { Vue, Component } from 'vue-property-decorator'

import ButtonArrow from '@/components/_uikit/buttons/ButtonArrow.vue'

@Component({
  components: { ButtonArrow },
})
export default class NotFound extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Страница не найдена',
    }
  }
}
